@import "../../assets/styles/theme-colors";

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px !important;
  padding: .75em .75em 0 .75em;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #E3E3E3;
}

.flimmo-button-1 {
  border-radius: 10px !important;
  background-color: mat-color($my-primary);
}