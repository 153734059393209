@import '@angular/material/theming';


$my-primary: (
        100: #FF522E,
        500: #FF522E,
        700: #FF522E,
        A100: #FF522E,
        A200: #FF522E,
        A400: #FF522E,
        contrast: (A100: #ffffff,
                A200: #ffffff,
                A400: #ffffff,
        )
);


$my-primary: mat-palette($my-primary, A100, A200, A400);

$my-accent: (
        100: #ffffff,
        500: #ffffff,
        700: #ffffff,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        contrast: (A100: #ffffff,
                A200: #ffffff,
                A400: #ffffff,
        )
);



// // below creates a primary palette with three shades of blue
$my-accent: mat-palette($my-accent, A100, A200, A400);

$my-warn: (
        100: #FF522E,
        500: #FF522E,
        700: #FF522E,
        A100: #FF522E,
        A200: #FF522E,
        A400: #FF522E,
        contrast: (A100: #ffffff,
                A200: #ffffff,
                A400: #ffffff,
        )
);
// // below creates a primary palette with three shades of blue
$my-warn: mat-palette($my-warn, A100, A200, A400);

.fc-primary {
        color: mat-color($my-primary, default);
}

$custom-collection: (
        100: #FF522E,
        500: #FF522E,
        700: #FF522E,
        warning : #FFCC00,
        success : #0BA800,
        danger: #EB0000,
        bg: #FFFFFF,
        input-bg: #E3E3E3,
        greentext: #0BA800,
        opaquetexts: #00000080,
        contrast: (warning : #000000,
                success : #FFFFFF,
                danger: #FFFFFF,
                bg: #FFFFFF,
                input-bg: #FFFFFF,
                greentext: #FFFFFF,
                opaquetexts: #FFFFFF)
);

$my-app-custom: mat-palette($custom-collection);

$my-app-theme: mat-light-theme($my-primary, $my-accent, $my-warn);
$my-app-theme: map_merge($my-app-theme, (custom: $my-app-custom));

$custom: map-get($my-app-theme, custom);

.primary-color {
        color: mat-color($my-primary) !important;
}

.grey-opaque {
        color: #AFAFAF;
}

.mat-background-primary {
        background: mat-color($my-primary) !important;
        ;
}

.greentext-color {
        color: mat-color($custom, greentext) !important;
}

.mat-danger {
        color: mat-color($custom, danger) !important;
}

.mat-warning {
        color: mat-color($custom, warning) !important;
}

.mat-success {
        color: mat-color($custom, success) !important;
}

.opaquetexts-color {
        color: mat-color($custom, opaquetexts) !important;
}

.mat-bg {
        background-color: #FFFFFF;
}

.mat-black {
        color: black;
}

.mat-danger {
        color: mat-color($custom, danger);
}

.mat-warning {
        color: mat-color($custom, warning);
}

.mat-success {
        color: mat-color($custom, success);
}

.mat-white {
        color: white;
}

.mat-transparent {
        background: transparent;
}

.footer-text-color {
        color: #FFFFFFBF;
}