// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// https://material.angular.io/guide/typography
@import '@angular/material/theming';

/* Material Icons font face and class definition */
$material-icon-path: "/assets/icons/";
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url($material-icon-path + 'material-icons.woff2') format('woff2'),
    url($material-icon-path + 'material-icons.woff') format('woff');

  font-display: swap;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Roboto
$roboto-path: "/assets/fonts/";
@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Thin.woff2') format('woff2'), url($roboto-path + 'Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url($roboto-path + 'Roboto-Thin.woff2') format('woff2'), url($roboto-path + 'Roboto-Thin.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-ThinItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url($roboto-path + 'Roboto-ThinItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-ThinItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Light.woff2') format('woff2'), url($roboto-path + 'Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url($roboto-path + 'Roboto-Light.woff2') format('woff2'), url($roboto-path + 'Roboto-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-LightItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url($roboto-path + 'Roboto-LightItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Regular.woff2') format('woff2'), url($roboto-path + 'Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url($roboto-path + 'Roboto-Regular.woff2') format('woff2'), url($roboto-path + 'Roboto-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-RegularItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-RegularItalic';
  src: url($roboto-path + 'Roboto-RegularItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-RegularItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Medium.woff2') format('woff2'), url($roboto-path + 'Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url($roboto-path + 'Roboto-Medium.woff2') format('woff2'), url($roboto-path + 'Roboto-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-MediumItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url($roboto-path + 'Roboto-MediumItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Bold.woff2') format('woff2'), url($roboto-path + 'Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url($roboto-path + 'Roboto-Bold.woff2') format('woff2'), url($roboto-path + 'Roboto-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-BoldItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url($roboto-path + 'Roboto-BoldItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-Black.woff2') format('woff2'), url($roboto-path + 'Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url($roboto-path + 'Roboto-Black.woff2') format('woff2'), url($roboto-path + 'Roboto-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url($roboto-path + 'Roboto-BlackItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url($roboto-path + 'Roboto-BlackItalic.woff2') format('woff2'), url($roboto-path + 'Roboto-BlackItalic.woff') format('woff');
  font-display: swap;
}

// Gilroy
$gilroy-font-path: "/assets/fonts/";

@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-font-path + 'Gilroy-Bold.eot');
  src: url($gilroy-font-path + 'Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url($gilroy-font-path + 'Gilroy-Bold.woff2') format('woff2'),
    url($gilroy-font-path + 'Gilroy-Bold.woff') format('woff'),
    url($gilroy-font-path + 'Gilroy-Bold.ttf') format('truetype'),
    url($gilroy-font-path + 'Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-font-path + 'Gilroy-SemiBold.eot');
  src: url($gilroy-font-path + 'Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url($gilroy-font-path + 'Gilroy-SemiBold.woff2') format('woff2'),
    url($gilroy-font-path + 'Gilroy-SemiBold.woff') format('woff'),
    url($gilroy-font-path + 'Gilroy-SemiBold.ttf') format('truetype'),
    url($gilroy-font-path + 'Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-font-path + 'Gilroy-Medium.eot');
  src: url($gilroy-font-path + 'Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url($gilroy-font-path + 'Gilroy-Medium.woff2') format('woff2'),
    url($gilroy-font-path + 'Gilroy-Medium.woff') format('woff'),
    url($gilroy-font-path + 'Gilroy-Medium.ttf') format('truetype'),
    url($gilroy-font-path + 'Gilroy-Medium.svg#Gilroy-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-font-path + 'Gilroy-Regular.eot');
  src: url($gilroy-font-path + 'Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url($gilroy-font-path + 'Gilroy-Regular.woff2') format('woff2'),
    url($gilroy-font-path + 'Gilroy-Regular.woff') format('woff'),
    url($gilroy-font-path + 'Gilroy-Regular.ttf') format('truetype'),
    url($gilroy-font-path + 'Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Define a custom typography config that overrides the font-family
$custom-typography: mat-typography-config($font-family: 'Gilroy',
    $headline: mat-typography-level(20px, 25px, 700), // Overline bold mat-h1
    $title: mat-typography-level(26px, 30px, 600), // Headline semibold mat-h2
    $subheading-2: mat-typography-level(20px, 24px, 600), // Subheadline semibold mat-h3
    $subheading-1: mat-typography-level(18px, 22px, 400), // Subsub regular mat-h4    
    $body-1: mat-typography-level(16px, 22px, 400, 'Roboto, monospace'), // Regular text mat-body-1
    $body-2: mat-typography-level(16px, 22px, 700, 'Roboto, monospace') // Bold text mat-body-2
  );

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

.mb1 {
  font: normal normal 400 16px/22px 'Roboto', monospace;
}

.mb2 {
  font: normal normal 700 16px/22px 'Roboto', monospace;
}

.cb {
  font: normal normal normal 100 16px/22px 'Gilroy';
}

// MATERIAL OVERRIDES ------

.f-button-label {
  font: normal normal bold 15px/21px Roboto;
  letter-spacing: 0.25px;
}

.f-chip {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0.25px;
}

// search form field label, to define via theme `input` or `form`?
.f-formfield-label {
  font: normal normal normal 12px/14px Roboto;
}


// EXTENSIONS ---------------


// hero

.f-hero-headline {
  font: normal normal 700 32px/28px Gilroy; // bold
}

.f-hero-subline {
  font: normal normal 500 20px/25px Gilroy; // medium
}


// swimlane preview

.f-preview-overline {
  font: normal normal 400 12px/17px Roboto;
}

.f-preview-headline {
  font: normal normal 700 14px/17px Roboto;
}

.f-preview-subline {
  font: normal normal 300 9px/12px Roboto;
}

.f-hint-link {
  font: normal normal bold 11px/16px Roboto;
}

.f-hint-text {
  font: normal normal normal 11px/16px Roboto;
}



// header & footer

.f-slogan {
  font: normal normal 500 12px/12px Gilroy; // medium
  letter-spacing: 0.6px;
}

.f-footer-link {
  font: normal normal 300 12px/14px Roboto;
}

.f-copyright {
  font: normal normal bold 10px/20px Roboto;
}

.f-copyright-text {
  font: normal normal 300 10px/12px Roboto;
}




// tv, maybe move to tv css, only use there!
.RR-10-12-bold {
  font: normal normal bold 10px/12px Roboto;
  letter-spacing: 0;
}

.RR-10-13 {
  font: normal normal 400 10px/13px Roboto;
  letter-spacing: 0;
}

.RB-10-12 {
  font: normal normal bold 10px/12px Roboto !important;
  letter-spacing: 0px !important;
}

.GR-20 {
  font: normal normal 500 20px/24px Gilroy !important;
}

.RR-8 {
  font: normal normal normal 8px/10px Roboto !important;
}

.RR-16-24 {
  font: normal normal normal 16px/24px Roboto !important;
  letter-spacing: 0px !important;
}