// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import 'assets/styles/theme-colors';
@import 'assets/styles/custom-fonts';
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($my-app-theme);

/* You can add global styles to this file, and also import other style files */
html {
  height: auto;
  background-color: #F6F6F6;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-display: swap;
  min-height: 100vh;
  background-color: #4E4E4E;
}

.myPanelClass {
  margin-top: 30px !important;
}

/** BOOTSTRAP **/
@import "assets/styles/bootstrap-utilities.min.css";

@import "assets/styles/global";

.nice-link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

::ng-deep .mat-ripple-element {
  display: none !important;
}

.custom-yellow {
  color: #4b453e;
}

.black {
  color: black;
}

a.button-link {
  text-decoration: none !important;
  cursor: pointer;
}

.big-button-link-table {
  width: 100%;
  margin-bottom: 20px;
}

.text-button-link-table {
  width: 100%;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.button-link-td-img {
  width: 100px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 4px;
  padding-bottom: 16px;
  vertical-align: top;
}

.big-button-img {
  width: 100px;
}

.button-link-td-text {
  text-align: left;
  vertical-align: top;
  padding-top: 14px;
  padding-left: 8px;
}

.button-link-td-icon {
  width: 30px;
  padding-right: 10px;
  text-align: right;
}



.overlay-shade {
  background: transparent linear-gradient(0deg, #1D1D1DCC 0%, #1D1D1D00 100%) 0% 0% no-repeat padding-box;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.mat-basic-chip {
  border: #8080808c solid 1px;
  border-radius: 13px;
  background: white;
}

.flimmo-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-width: 200px;
}

.select-off {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ellipsis {
  text-overflow: ellipsis !important;
}

.center-h1 {
  align-self: center;
  margin-bottom: 0px !important;
}

$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and (max-width: 959px);
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
$mat-gt-xs: screen and (min-width: 600px);
$mat-gt-sm: screen and (min-width: 960px);
$mat-gt-md: screen and (min-width: 1280px);
$mat-gt-xl: screen and (min-width: 1920px);

@import 'assets/styles/inputs';

.custom-material-icon-info {
  font-size: inherit !important;
  font-weight: inherit !important;
  top: 2px !important;
  position: relative !important;
  max-width: 18px !important;
}

.custom-material-icon-open-in-new {
  font-size: inherit !important;
  top: 2px !important;
  position: relative !important;
  max-width: 18px !important;
}

markdown>h1 {
  color: mat-color($my-primary) !important;
}

markdown a:visited {
  color: mat-color($my-primary) !important;
}

markdown>p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-bottom: 16px !important;
}

markdown ::ng-deep a:-webkit-any-link {
  color: mat-color($my-primary) !important;
}

markdown :link {
  color: mat-color($my-primary) !important;
}

markdown a:-webkit-any-link {
  color: mat-color($my-primary) !important;
  cursor: pointer;
  text-decoration: underline;
}



.slide {
  display: none;
  text-align: center;
}

.slide.active {
  display: block;
}

.slideshow-container {
  display: grid;
  justify-content: center;
}

.slideshow {
  position: relative;
  z-index: 0;
  width: 320px;
  height: 320px;
  justify-self: center;
}

.slide-button-left {
  position: absolute;
  z-index: 1;
  top: calc(50% - 20px);
  left: 0px;
  opacity: 0;
  -webkit-opacity: 0;
  transition: opacity 0.5s;
}

.slide-button-right {
  position: absolute;
  z-index: 1;
  top: calc(50% - 20px);
  right: 0px;
  opacity: 0;
  -webkit-opacity: 0;
  transition: opacity 0.5s;
}

@media (hover: hover) and (pointer: fine) {

  .slideshow:hover .slide-button-left {
    opacity: 1;
    -webkit-opacity: 1;
    cursor: pointer;
  }

  .slideshow:hover .slide-button-right {
    opacity: 1;
    -webkit-opacity: 1;
    cursor: pointer;
  }
}

.slide-indicators {
  z-index: 1;
  position: absolute;
  bottom: 10px;
  display: flex;

  justify-content: center;
  width: 100%;
}

.slide-indicators-bg {
  background-color: #11111111;
  height: 18px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}

.slide-indicator-touch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 12px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.slide-indicator {
  display: inline-block;
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 50%;
  pointer-events: none;
}

.slide-indicator.active {
  background-color: #FF522E;
}