.clickable {
  cursor: pointer;
}

.flimmo-outline-button {
  font-size: 15px !important;
  border: 1px solid #707070 !important;
  border-radius: 50px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: fit-content !important;
  width: fit-content !important;
  background: inherit !important;
  height: 36px !important;
  cursor: pointer !important;
  color: black;
}

.flimmo-meint-box {
  margin-top: 40px;
  padding: 20px 20px 30px;
}

.-px-3 {
  padding-right: -1rem !important;
  padding-left: -1rem !important
}

.mat-app-background-white {
  background: white;
}

.mat-app-background-1 {
  background: #F6F6F6;
}

.mat-app-background-2 {
  background: #E9E9E9;
  opacity: 1;
}

.mat-app-background-3 {
  background: #DFDFDF;
}

.mat-app-background-4 {
  background: #4E4E4E;
}

.mat-button-toggle-button:focus {
  outline: none;
}

.outline-none {
  outline: none !important;
}

.bb-1-grey {
  border-bottom: 1px solid #0000001F;
}

.px10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.mat-dialog-container {
  border-radius: 15px !important;
  overflow-x: hidden!important;
  overflow-y: visible!important;
  background: #F6F6F6!important;
}

.absolute-zero {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image-ratio-container {
  padding-top: 56.25% !important;
}

.imgWeb > img {
  height: 506px !important;
}

.overline-margin {
  margin-bottom: 3px !important;
}

.image-overline-gap {
  padding-top: 40px;
}

.overline-title-gap {
  padding-top: 0px;
  margin-bottom: 0px!important;
}
.title-content-gap {
  padding-top: 20px!important;
}
.title-content-gap-redtext {
  padding-top: 20px!important;
}
/**
Mozilla scrollbar hide
 */
.scrollbar-off {
  scrollbar-width: none;
}

.icon-position {
  position: relative !important;
  left: 10px !important;
}

details {
  // padding-top: 10px;
  // padding-bottom: 10px;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
}


details summary {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  cursor: pointer;  
  width: 100%;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;  
  list-style: none;
  font-weight: 600;
}

details summary::-webkit-details-marker {  
  display: none; 
}

details summary h4 {
  margin: 0 !important; 
  font-weight: 600 !important; 
}


details p {
  margin-top: 16px !important;
}

.custom-icon {
  transition: transform 0.3s ease;
  color: mat-color($my-primary); 
  margin-right: 10px;   
  margin-left: 10px;   
}

.custom-icon-white {
  transition: transform 0.3s ease;
  color: #FFFFFF; 
  margin-top: 8px;
  margin-right: 10px;   
  margin-left: 10px;   
}

.download-button {  
  border-radius: 10px !important;
  background-color: mat-color($my-primary);
  padding-left: 0px !important;
  padding-right: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  min-width: fit-content !important;
  width: fit-content !important;
}

details[open] .custom-icon {
  transform: rotate(180deg);
}



markdown {
  > p > a > img {
    margin-right: 12px!important;
    margin-bottom: 12px!important;
  }

  > h1 {
    padding-top: 12px !important;
  }

  // > p + p {
  //   padding-top: 20px !important;
  // }

  > p + h2 {
    padding-top: 20px !important;
  }

  > h2 {
    padding-top: 12px !important;
  }

  > p + h3 {
    padding-top: 20px !important;
  }

  > h3 {
    padding-top: 12px !important;
  }

  > h3 + h4 {
    padding-top: 2px!important;
  }

  > h4 {
    padding-top: 10px !important;
  }

  > ul {
    padding-inline-start: 20px !important;

    > li:first-child {
      padding-top: 0px !important;
    }

    > li {
      padding-top: 4px !important;
    }
  }

  > h6 { // footer
    font: normal italic normal 12px/14px Roboto !important;
  }
}
